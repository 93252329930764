/* width */
.scrollbar::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.nav-controls {
    width: 15%;
    overflow-y: auto;
}

.trello-icon {
    width: 1.2em;
    height: 1.2em;
    margin-right: 5px;
}

#list-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

#list-menu {
    font-size: 0.8rem;
}

.list-menu-name {
    font-size: small;
    flex-shrink: 0;
    font-weight: 800;
}

#list-menu li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

#list-menu li {
    transition: all 0.2s ease;
    border-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0);
    border-left-color: rgba(0, 0, 0, 0);
    border-top-color: rgba(0, 0, 0, 0);
    border-right-color: rgba(0, 0, 0, 0);
    border-style: solid;
}

#list-menu li:hover {
    transition: all 0s ease;
    border-bottom-color: black;
    border-left-color: black;
}

.map-card {

}

.map-card h1 {
    margin: 0;
}

.map-badges {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
}

.map-badges > * {
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.75em;
    margin-right: 0.5em;
}

.map-description {
    font-size: 1.2em;
}

.trello-link{
    margin-top : 1em;
}

.card-date{
    font-size: 0.9em;
    margin-bottom: 1em;
    font-style: italic;
    display: flex;
    flex-direction: column;
}

#list-menu .MuiButtonBase-root {
    min-height: 0.5em !important;
}

#list-menu .MuiAccordionSummary-content {
    margin: 0.5em !important;
}

#map-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#map-legend {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;
    height: fit-content;
    background-color: black;
    font-size: 0.8em;
}

#mainMap {
    flex: 1 1 auto;
}

.legend-item {
    height: 1em;
    display: flex;
    margin-right: 2em;
    align-items: center
}
